.role-page-content .role-user-count {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.role-page-content .role-user-count span {
  font-size: 15px;
  font-weight: 500;
}
.role-page-content .role-user-count .fi {
  font-size: 15px;
  color: #777;
}
.role-page-content .role-authorization-allow .fi {
  font-size: 17px;
}
.role-detail-title {
  padding: 20px 0;
}
