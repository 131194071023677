.ez-priority-wrap .ez-priority {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  cursor: default;
}
.ez-priority-wrap .ez-priority .fi {
  font-size: 14px;
}
