.primary-layout .footer {
  display: flex;
  justify-content: space-between;
  height: 65px;
  width: 100%;
}
.primary-layout .footer .footer-container {
  max-width: 1340px;
  margin: auto;
}
.primary-layout .footer .footer-container .footer-text {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.primary-layout .footer .footer-container .footer-text .footer-copyright {
  margin-right: 7px;
  color: #B5B5C3;
}
.primary-layout .footer .footer-container .footer-text .footer-logo-link {
  color: #3F4254;
  font-weight: 500;
}
