.primary-layout .header-wrapper {
  position: sticky;
  top: 0;
  z-index: 101;
  background: #fff;
  height: 65px;
  box-shadow: none;
  transition: 0.2s;
  width: 100%;
}
.primary-layout .header-wrapper.shadow {
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08);
}
.primary-layout .header {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  margin: auto;
  padding: 7px 30px;
  gap: 10px;
}
.primary-layout .header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.primary-layout .page-menu-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
}
