.rte-autocomplete{
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(calc(-100% + 24px));
    display: block;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    background-clip: padding-box;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
}

.rte-autocomplete:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #f1f1f1;
    border-bottom-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: -7px;
    right: 9px;
}

.rte-autocomplete:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    position: absolute;
    top: -6px;
    right: 10px;
}

.rte-autocomplete > li.loading {
    height: 16px;
}

.rte-autocomplete > li > a {
    display: block;
    padding: 7px 15px;
    clear: both;
    font-weight: normal;
    line-height: 20px;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
    transition: 0s;
}

.rte-autocomplete >li > a:hover {
    text-decoration: none;
    background-color: #F1FAFF;
}

.rte-autocomplete > li > a:focus, .rte-autocomplete:hover > a, .rte-autocomplete:focus > a {
    color: #fff;
    text-decoration: none;
    background-color: #0D99FF;
}

.rte-autocomplete >.active > a, .rte-autocomplete > .active > a:hover, .rte-autocomplete > .active > a:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0D99FF;
}