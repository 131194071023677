.simple-picker {
  border: 1px solid #d9d9d9;
  padding-left: 12px;
  height: 32px;
  border-radius: 2px;
}
.simple-picker__btn-remove {
  cursor: pointer;
}
.simple-picker__btn-remove .fi {
  font-size: 17px;
}
