.dashboard__deadline-today img {
  width: 170px;
}
.dashboard__notifications img {
  width: 170px;
}
.db-noti-item {
  border: 1px solid #f1f1f1;
  border-radius: 0.65rem;
  padding: 15px;
  background: url("../../../assets/images/abstract-2.svg") top right no-repeat;
  background-size: auto 70%;
}
.db-noti-item__detail h3 a {
  color: var(--ez-text-gray-700);
}
.db-noti-item__detail h3 a:hover {
  text-decoration: underline;
}
