.text-avatar {
  display: flex;
  align-items: center;
  gap: 5px;
  user-select: none;
}
.text-avatar .ant-avatar {
  outline: 2px solid #fff;
}
.text-avatar.clickable {
  cursor: pointer;
  user-select: none;
}
.user-avatar-tick {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  color: #fff;
  background-color: var(--ez-info);
}
.user-avatar-tick .fi {
  width: 100%;
  height: 100%;
}
