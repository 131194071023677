.primary-layout .page-breadcrumb-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.primary-layout .page-breadcrumb-wrapper .page-title {
  margin: 0;
  color: #181C32;
  font-size: 20px;
  font-weight: 600;
  padding: 3px 0;
}
.primary-layout .page-breadcrumb-wrapper .breadcrumb-space {
  margin: 0 20px;
  height: 25px;
  border-left: 1px solid #E4E6EF;
}
.primary-layout .page-breadcrumb-wrapper .page-breadcrumb {
  font-size: 13px;
  padding: 3px 0;
}
