.primary-layout.layout-wrapper {
  padding-left: 100px;
}
.primary-layout .page-wrapper {
  background: #F3F6F9;
}
.primary-layout .page-container {
  margin: auto;
  min-height: calc(100vh - 65px - 65px);
}
