.btn-action {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
  background-color: #f5f8fa;
}
.btn-action i {
  color: #333;
}
.btn-action:hover {
  background-color: #FFA800;
}
.btn-action:hover i {
  color: #fff;
}
.btn-action.disabled {
  cursor: not-allowed !important;
}
.btn-action.disabled:hover {
  background-color: #f5f8fa !important;
}
.btn-action.disabled:hover i {
  color: #333;
}
