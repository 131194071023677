.primary-layout .page-menu {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.primary-layout .page-menu .menu-item {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
}
.primary-layout .page-menu .menu-item .fi {
  font-size: 18px;
  color: #B5B5C3;
}
.primary-layout .page-menu .menu-item:hover {
  background-color: #F3F6F9;
}
.primary-layout .page-menu .menu-item:hover .fi {
  color: #3699FF;
}
