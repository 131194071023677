:root {
    --component-width: 20%;
}

body{
    background-color: #f3f6f9;
}

.container {
    /* background-color: wheat; */
}

.formcomponents {
    margin-left: 12px;
    background-color: white;
    width: calc(100vw - 3rem);
}

.formbuilder .formio-form {
    background-color: white;
    padding: 70px 40px 20px;
    border: 1px rgba(0, 0, 0, 0.361) dashed;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 10px;
}
.gu-transit{
    width: 100%;
    background-color:  #0882ed !important;
    color: white !important;
    padding-top: 10px;
    padding-bottom: 10px;
}

[ref="sidebar-anchor"] {
    width: 100%;
}

/* .drag-and-drop-alert.alert.alert-info.no-drag{
    position: absolute;
    margin: 0px;
    left: 20px;
    right: 20px;
    top: 20px
} */



[ref="sidebar-container"] {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
}

.formcomponents {
    box-sizing: border-box;
    padding: 0;
    margin: 15px;
}

@media (min-width: 576px){

    .formcomponents{
        margin: 0px;
    }
    [ref="sidebar"]{
        height: 100%;
    }
    [ref="sidebar-container"] {
        display: flex;
        flex-direction: column;
    }
    .formcomponents {
        margin: 0px;
        flex: 0 0 var(--component-width);
        max-width: var(--component-width);
    }

    .formarea[ref="form"] {
        flex: 0 0 calc(100% - var(--component-width));
        max-width: calc(100% - var(--component-width));
    }
    .formio.builder.row.formbuilder{
        flex-direction: row-reverse;
    }
}

@media (min-width: 768px) {
    [ref="sidebar-component"]{
        width: 100%;
    }
    .formbuilder .formio-form {
        padding:70px 10%;
    }
    .formcomponents {
        margin: 0px;
        flex: 0 0 var(--component-width);
        max-width: var(--component-width);
    }

    .formarea[ref="form"] {
        flex: 0 0 calc(100% - var(--component-width));
        max-width: calc(100% - var(--component-width));
    }
    .formio.builder.row.formbuilder{
        flex-direction: row-reverse;
    }
    [ref="sidebar-container"] {
        display: flex;
        flex-direction: column;
    }
}

[ref="sidebar-component"]{
    /* margin: 2px !important; */
    color: #373a36;
    border-color: #3193ef !important;
    background-color: white;
}

[ref="sidebar-component"]:hover{
    cursor: grab !important;
    color: #333;
    background-color: #eee !important;
    /* border-color: #456a8c !important; */
    box-shadow: 0px 0.5px 4px #1883e7 !important;
}





[ref="component"].form-group.has-feedback.formio-component.formio-component-tabs.formio-component-tabsdisplay {
    padding-top: 20px;
}

.preview-form{
    background-color: white;
    padding: 10%;
    border: 1px rgba(0, 0, 0, 0.361) dashed;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    margin: 40px 0px;
}


/* remove help link  */
[rel="noopener noreferrer"]{
    display: none;
}

.form-permission .builder-component{
    background-color: white;
}

.form-permission .builder-component:hover{
    border: 1px dashed rgb(50, 112, 182)
}

.form-permission .permission-setting.component-settings-button{
    /* font-size: 14px; */
    /* background-color: white; */
}

.editable-component, .hidden-component, .readonly-component{
    padding: 0px 8px 12px 8px;
    border: 1px dashed rgba(255, 255, 255, 0);
    border-radius: 5px;
}

.form-permission .editable-component{
    background-color: rgb(244, 251, 255);
    border-radius: 5px;
}

.form-permission .editable-component input:first-child,
.form-permission .editable-component textarea:first-child{
    background-color: rgb(248, 252, 255);
}

.form-permission .hidden-component{
    background-color: rgb(240, 240, 240);
    border-radius: 5px;
    color: rgb(179, 179, 179);
}

.form-permission.hidden-component input:first-child,
.form-permission.hidden-component textarea:first-child{
    background-color: rgb(245, 245, 245);
}

/* .hidden-component .drag-container{
    border-color: #c4c4c4;
} */

.form-permission .readonly-component{
    background-color: rgb(255, 252, 244);
    border-radius: 5px;
}

.form-permission .readonly-component input:first-child,
.form-permission .readonly-component textarea:first-child{
    background-color: rgb(255, 252, 247)
}

.form-permission input, .form-permission textarea{
    pointer-events: none;
}

.form-permission .drag-container{
    border: none;
}

.form-permission .table > :not(caption) > * > * {
    background-color: rgba(255, 255, 255, 0) !important;
}

/* .form-permission .formbuilder .drag-container{
    padding: 10px 0px;
}    */



/* .formbuilder .formio-component {
    margin: 0px;
    padding: 0px 10px 10px 10px;
}
.formbuilder .formio-component-columns{
    padding: 0px;
} */

.mention-wrapper{
    width: 100%;
}