.sidebar-calendar-drawer .ant-drawer-body {
  overflow: hidden;
  padding: 0;
}
.sidebar-calendar-wrapper {
  padding: 24px;
  height: 100%;
  overflow: auto;
}
.sidebar-calendar-wrapper .sidebar-calendar {
  width: 100%;
}
.sidebar-calendar-wrapper .sidebar-calendar .sidebar-calendar-content {
  display: flex;
  width: 100%;
}
.sidebar-calendar-wrapper .sidebar-calendar .sidebar-calendar-content .time-label-wrapper {
  width: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sidebar-calendar-wrapper .sidebar-calendar .sidebar-calendar-content .time-label-wrapper .time-label {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.sidebar-calendar-wrapper .sidebar-calendar .sidebar-calendar-content .time-label-wrapper .time-label.active span {
  color: #50CD89;
  background: #E8FFF3;
  border: 1px solid #50CD89;
}
.sidebar-calendar-wrapper .sidebar-calendar .sidebar-calendar-content .time-label-wrapper .time-label span {
  display: inline-block;
  font-size: 12px;
  width: 100%;
  line-height: 24px;
  margin-top: -13px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid transparent;
}
.sidebar-calendar-wrapper .sidebar-calendar .sidebar-calendar-content .events-wrapper {
  width: 100%;
  background: #fafafa;
  position: relative;
  margin-left: 10px;
  background: linear-gradient(to bottom, #f1f1f1, #f1f1f1 1px, #fff 1px, #fff);
  background-size: 100% 45px;
}
.sidebar-calendar-wrapper .sidebar-calendar .sidebar-calendar-content .events-wrapper .event-item {
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 2px;
  margin-top: -7px;
  cursor: pointer;
  background: #009EF7;
}
.sidebar-calendar-wrapper .sidebar-calendar .sidebar-calendar-content .events-wrapper .event-item:after {
  content: '';
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: 0;
  width: 100%;
  background: transparent;
}
.sidebar-calendar-wrapper .sidebar-calendar .sidebar-calendar-content .events-wrapper .event-item.overdue {
  background: #F1416C;
}
.sidebar-calendar-wrapper .sidebar-calendar .sidebar-calendar-content .events-wrapper .event-item.near-due {
  background: #FFC700;
}
