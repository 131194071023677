.ez-card {
  border: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 0.625rem;
}
.ez-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 70px;
  padding: 0 2rem;
}
.ez-card .card-header .card-title {
  display: flex;
  align-items: center;
}
.ez-card .card-header .card-title h1,
.ez-card .card-header .card-title h2,
.ez-card .card-header .card-title h3,
.ez-card .card-header .card-title h4,
.ez-card .card-header .card-title h5,
.ez-card .card-header .card-title h6 {
  margin: 0;
}
.ez-card .card-body {
  flex: 1 1 auto;
  padding: 0 2rem;
}
.ez-card .card-footer {
  padding: 0 2rem;
  min-height: 32px;
}
