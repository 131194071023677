.user-task-item {
  width: 100%;
  display: flex;
  background: #fff;
  padding: 10px;
  gap: 10px;
  align-items: center;
  outline: 1px solid #F3F6F9;
  border-left: 2px solid transparent;
  transition: background-color 0.1s;
  position: relative;
}
.user-task-item:hover {
  background-color: #fafafa;
}
.user-task-item.overdue {
  border-left: 2px solid var(--ez-danger);
}
.user-task-item.near-due {
  border-left: 2px solid var(--ez-warning);
}
.user-task-item.new {
  z-index: 1;
  background-color: var(--ez-success-light);
}
.user-task-item .user-task-type {
  padding: 0 7px;
}
.user-task-item .user-task-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  overflow: hidden;
}
.user-task-item .user-task-workflow {
  overflow: hidden;
  max-width: 200px;
}
.user-task-item .user-task-workflow h4 {
  line-height: 21px;
  padding: 0 5px;
  border-radius: 3px 0 0 3px;
  background: #e3fcef;
  color: #006644;
}
.user-task-item .user-task-workflow span {
  line-height: 21px;
  padding: 0 5px;
  border-radius: 0 3px 3px 0;
  background: #e3fcef;
  color: #006644;
}
.user-task-item .user-task-meta {
  gap: 10px;
}
