.form-viewer-layout {
  background: #F3F6F9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.form-viewer-layout .form-viewer-container {
  width: 100%;
}
.form-viewer-layout .form-viewer-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 50px;
}
.form-viewer-layout .form-viewer-footer .footer-copyright {
  margin-right: 7px;
  color: #B5B5C3;
}
.form-viewer-layout .form-viewer-footer .footer-logo-link {
  color: #3F4254;
  font-weight: 500;
}
