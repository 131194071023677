.primary-layout .page-sidebar {
  position: fixed;
  width: 100px;
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-right: 1px solid #EFF2F5;
}
.primary-layout .page-sidebar .logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding-top: 10px;
}
.primary-layout .page-sidebar .logo img {
  width: 45px;
}
.primary-layout .page-sidebar .fi {
  font-size: 18px;
  height: 100%;
}
.primary-layout .page-sidebar .ant-menu {
  border: none;
}
.primary-layout .page-sidebar .sidebar-menu,
.primary-layout .page-sidebar .bottom-sidebar-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 19px 0;
}
