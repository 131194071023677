.page-toolbar {
  position: sticky;
  width: 100%;
  top: 65px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 55px;
  border-top: 1px solid #EFF2F5;
  z-index: 101;
}
.page-toolbar.shadow {
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08);
}
