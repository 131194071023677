@import "~bootstrap/dist/css/bootstrap.css";
@import "~formiojs-llq/dist/formio.full.min.css";
@import "assets/styles/formio.css";
/* Formio */
.formio-dialog {
  z-index: 1000;
}


h3, h4 {
  font-size: initial;
}

a {
  text-decoration: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~antd/dist/antd.css';
@import "variables.css";

@import '_libs/form-viewer/styles/common.css';
@import '_libs/form-viewer/styles/index.css';

:root {
  --ez-gray-100: #f5f8fa;
  --ez-gray-200: #eff2f5;
  --ez-gray-300: #E4E6EF;
  --ez-gray-400: #B5B5C3;
  --ez-gray-500: #A1A5B7;
  --ez-gray-600: #7E8299;
  --ez-gray-700: #5E6278;
  --ez-gray-800: #3F4254;
  --ez-gray-900: #181C32;
  --ez-gray-100-rgb: 245,248,250;
  --ez-gray-200-rgb: 239,242,245;
  --ez-gray-300-rgb: 228,230,239;
  --ez-gray-400-rgb: 181,181,195;
  --ez-gray-500-rgb: 161,165,183;
  --ez-gray-600-rgb: 126,130,153;
  --ez-gray-700-rgb: 94,98,120;
  --ez-gray-800-rgb: 63,66,84;
  --ez-gray-900-rgb: 24,28,50;
  --ez-white: #ffffff;
  --ez-light: #f5f8fa;
  --ez-primary: #009ef7;
  --ez-secondary: #E4E6EF;
  --ez-success: #50cd89;
  --ez-info: #7239ea;
  --ez-warning: #ffc700;
  --ez-danger: #f1416c;
  --ez-dark: #181C32;
  --ez-primary-active: #0095e8;
  --ez-secondary-active: #B5B5C3;
  --ez-light-active: #eff2f5;
  --ez-success-active: #47be7d;
  --ez-info-active: #5014d0;
  --ez-warning-active: #f1bc00;
  --ez-danger-active: #d9214e;
  --ez-dark-active: #131628;
  --ez-primary-light: #f1faff;
  --ez-secondary-light: #f5f8fa;
  --ez-success-light: #e8fff3;
  --ez-info-light: #f8f5ff;
  --ez-warning-light: #fff8dd;
  --ez-danger-light: #fff5f8;
  --ez-dark-light: #eff2f5;
  --ez-primary-inverse: #ffffff;
  --ez-secondary-inverse: #3F4254;
  --ez-light-inverse: #7E8299;
  --ez-success-inverse: #ffffff;
  --ez-info-inverse: #ffffff;
  --ez-warning-inverse: #ffffff;
  --ez-danger-inverse: #ffffff;
  --ez-dark-inverse: #ffffff;
  --ez-white-rgb: 255,255,255;
  --ez-light-rgb: 245,248,250;
  --ez-primary-rgb: 0,158,247;
  --ez-secondary-rgb: 228,230,239;
  --ez-success-rgb: 80,205,137;
  --ez-info-rgb: 114,57,234;
  --ez-warning-rgb: 255,199,0;
  --ez-danger-rgb: 241,65,108;
  --ez-dark-rgb: 24,28,50;
  --ez-text-white: #ffffff;
  --ez-text-primary: #009ef7;
  --ez-text-secondary: #E4E6EF;
  --ez-text-light: #f5f8fa;
  --ez-text-success: #50cd89;
  --ez-text-info: #7239ea;
  --ez-text-warning: #ffc700;
  --ez-text-danger: #f1416c;
  --ez-text-dark: #181C32;
  --ez-text-muted: #A1A5B7;
  --ez-text-gray-100: #f5f8fa;
  --ez-text-gray-200: #eff2f5;
  --ez-text-gray-300: #E4E6EF;
  --ez-text-gray-400: #B5B5C3;
  --ez-text-gray-500: #A1A5B7;
  --ez-text-gray-600: #7E8299;
  --ez-text-gray-700: #5E6278;
  --ez-text-gray-800: #3F4254;
  --ez-text-gray-900: #181C32;
  --bs-white: #ffffff;
  --bs-light: #f5f8fa;
  --bs-primary: #009ef7;
  --bs-secondary: #E4E6EF;
  --bs-success: #50cd89;
  --bs-info: #7239ea;
  --bs-warning: #ffc700;
  --bs-danger: #f1416c;
  --bs-dark: #181C32;
  --bs-white-rgb: 255, 255, 255;
  --bs-light-rgb: 245, 248, 250;
  --bs-primary-rgb: 0, 158, 247;
  --bs-secondary-rgb: 228, 230, 239;
  --bs-success-rgb: 80, 205, 137;
  --bs-info-rgb: 114, 57, 234;
  --bs-warning-rgb: 255, 199, 0;
  --bs-danger-rgb: 241, 65, 108;
  --bs-dark-rgb: 24, 28, 50;
  --bs-black-rgb: 0, 0, 0;
}
html,
body {
  color: #181C32;
}
.fi {
  display: inline-flex;
}
.data-table.row-clickable tbody tr {
  cursor: pointer;
}
.page-content-wrapper .page-content {
  padding: 30px;
  margin: auto;
}
.page-content-wrapper .page-content.box {
  max-width: 1260px;
}
.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.ant-btn.btn-success {
  background: #50CD89;
  border-color: #50CD89;
}
.react-flow__attribution {
  display: none;
}
.tox-statusbar__branding {
  display: none;
}
.ant-input[disabled] {
  background-color: #fafafa !important;
  color: rgba(0, 0, 0, 0.8) !important;
}
.no-margin {
  margin: 0!important;
}
.no-margin-top {
  margin-top: 0!important;
}
.no-margin-bottom {
  margin-bottom: 0!important;
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.bg-light-primary {
  background-color: #F1FAFF !important;
}
.text-primary {
  color: #009EF7 !important;
}
.bg-light-warning {
  background-color: #FFF8DD !important;
}
.bg-light-success {
  background-color: var(--ez-success-light) !important;
}
.bg-success {
  background-color: var(--ez-success) !important;
}
.bg-light-danger {
  background-color: #FFF5F8 !important;
}
.bg-danger {
  background-color: var(--ez-danger) !important;
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
.mt-0 {
  margin-top: 0!important;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mt-5 {
  margin-top: 50px;
}
.mt-6 {
  margin-top: 60px;
}
.mb-0 {
  margin-bottom: 0!important;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-4 {
  margin-bottom: 40px;
}
.pt-0 {
  padding-top: 0!important;
}
.pt-1 {
  padding-top: 10px;
}
.pt-2 {
  padding-top: 20px;
}
.pt-3 {
  padding-top: 30px;
}
.pt-4 {
  padding-top: 40px;
}
.pr-0 {
  padding-right: 0!important;
}
.pr-5px {
  padding-right: 5px;
}
.pr-10px {
  padding-right: 10px;
}
.pr-20px {
  padding-right: 20px;
}
.pr-30px {
  padding-right: 30px;
}
.pr-40px {
  padding-right: 40px;
}
.pb-0 {
  padding-bottom: 0!important;
}
.pb-5px {
  padding-bottom: 5px;
}
.pb-10px {
  padding-bottom: 10px;
}
.pb-20px {
  padding-bottom: 20px;
}
.pb-30px {
  padding-bottom: 30px;
}
.pb-40px {
  padding-bottom: 40px;
}
.ant-menu,
.ant-menu-item a {
  color: #b5b5c3;
}
.ant-menu-title-content {
  color: #333;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover,
.ant-menu-item-selected .ant-menu-title-content {
  color: #60aeff;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 1000;
}
.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}
/****CUSTOM FIELDS****/
.ez-e-sign {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ez-e-sign .ez-e-sign-label {
  padding: 0 0 8px;
  line-height: 1.5715;
}
.ez-e-sign .ez-e-sign-container {
  width: 100%;
  display: flex;
}
.ez-e-sign .ez-e-sign-item {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #FFC700;
  padding: 20px;
  background-color: #FFF8DD;
}
.ez-e-sign .ez-e-sign-item .ez-e-sign-content {
  flex: 1;
}
.ez-e-sign .ez-e-sign-item .ez-e-sign-content h3 {
  font-size: 17px;
  font-weight: 500;
  color: #555;
}
.ez-e-sign .ez-e-sign-item .ez-e-sign-content p {
  margin: 0;
  font-size: 14px;
  color: #777;
}
.ez-e-sign .ez-e-sign-item .ez-e-sign-content .ez-e-sign-signers {
  margin-top: 10px;
}
.ez-e-sign .ez-e-sign-item .ez-e-sign-content .ez-e-sign-signers span {
  font-size: 12px;
  font-weight: 300;
  color: #777;
}
.ez-e-sign .ez-e-sign-item .ez-e-sign-action {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.e-sign-viewer-modal .ant-modal-body {
  height: calc(100vh - 155px);
}
.form-item-no-margin-bottom .ant-form-item {
  margin-bottom: 0;
}
/****CUSTOM FILED BUILDER****/
.ez-fb-e-sign {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 17px;
}
.ez-fb-e-sign .ez-fb-e-sign-label {
  padding: 0 0 8px;
  line-height: 1.5715;
}
.ez-fb-e-sign .ez-fb-e-sign-container {
  width: 100%;
  display: flex;
}
.ez-fb-e-sign .ez-fb-e-sign-item {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #FFC700;
  padding: 20px;
  background-color: #FFF8DD;
}
.ez-fb-e-sign .ez-fb-e-sign-item .ez-fb-e-sign-content {
  flex: 1;
}
.ez-fb-e-sign .ez-fb-e-sign-item .ez-fb-e-sign-content h3 {
  font-size: 17px;
  font-weight: 500;
  color: #555;
}
.ez-fb-e-sign .ez-fb-e-sign-item .ez-fb-e-sign-content p {
  margin: 0;
  font-size: 14px;
  color: #777;
}
.ez-fb-e-sign .ez-fb-e-sign-item .ez-fb-e-sign-content .ez-fb-e-sign-signers {
  margin-top: 10px;
}
.ez-fb-e-sign .ez-fb-e-sign-item .ez-fb-e-sign-content .ez-fb-e-sign-signers span {
  font-size: 12px;
  font-weight: 300;
  color: #777;
}
.ez-fb-e-sign .ez-fb-e-sign-item .ez-fb-e-sign-action {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: end;
}
/**DOCUMENT NUMBER**/
.ez-fb-doc-number {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-bottom: 17px;
}
.ez-fb-doc-number .ez-fb-doc-number-label {
  padding: 0 0 8px;
  line-height: 1.5715;
}
.ez-fb-doc-number .ez-fb-doc-number-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ez-fb-doc-number .ez-fb-doc-number-container .ez-fb-doc-number-content {
  flex: 1;
}
.ez-fb-doc-number .ez-fb-doc-number-container .ez-fb-doc-number-action {
  width: 180px;
  text-align: right;
}
.lbl-icon {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.flex {
  display: flex;
}
.flex-inline {
  display: inline-flex;
}
.align-items-center {
  align-items: center!important;
}
.align-items-start {
  align-items: start!important;
}
.justify-content-center {
  justify-content: center!important;
}
.justify-content-space-between {
  justify-content: space-between !important;
}
.flex-column {
  flex-direction: column!important;
}
.gap-1 {
  gap: 5px;
}
.gap-2 {
  gap: 10px;
}
.gap-3 {
  gap: 15px;
}
.pull-right {
  margin-left: auto;
}
.pull-left {
  margin-right: auto;
}
.pull-bottom {
  margin-top: auto;
}
.pull-top {
  margin-bottom: auto;
}
.float-right {
  float: right;
}
/**Wave**/
.wave {
  background-color: rgba(27, 197, 189, 0.1);
  overflow: hidden;
  position: relative;
}
.wave:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: -1;
  overflow: hidden;
}
.wave:after {
  content: "";
  width: 1000px;
  height: 1025px;
  position: absolute;
  bottom: 65%;
  left: -250px;
  border-radius: 35%;
  background: white;
  z-index: 0;
  transform: rotate(45deg);
  animation: animate-wave 20s infinite linear;
}
.wave > div {
  z-index: 2;
  position: relative;
}
@keyframes animate-wave {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
/**End: Wave**/
.text-gray-100 {
  color: var(--ez-text-gray-100) !important;
}
.text-gray-200 {
  color: var(--ez-text-gray-200) !important;
}
.text-gray-300 {
  color: var(--ez-text-gray-300) !important;
}
.text-gray-400 {
  color: var(--ez-text-gray-400) !important;
}
.text-gray-500 {
  color: var(--ez-text-gray-500) !important;
}
.text-gray-600 {
  color: var(--ez-text-gray-600) !important;
}
.text-gray-700 {
  color: var(--ez-text-gray-700) !important;
}
.text-gray-800 {
  color: var(--ez-text-gray-800) !important;
}
.text-gray-900 {
  color: var(--ez-text-gray-900) !important;
}
/**Badge**/
.badge {
  --bs-badge-padding-x: 8px;
  --bs-badge-padding-y: 7px;
  --bs-badge-font-weight: 600;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: 4px;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: var(--bs-badge-border-radius);
}
.badge {
  --bs-badge-color: var(--ez-badge-color);
  font-size: 12px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.badge.badge-circle,
.badge.badge-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  min-width: 1.75rem;
  padding: 0 0.1rem;
  line-height: 0;
}
.badge.badge-circle {
  border-radius: 50%;
  padding: 0;
  min-width: unset;
  width: 1.75rem;
}
.badge.badge-sm {
  min-width: 1.5rem;
  font-size: 0.8rem;
}
.badge.badge-sm.badge-square {
  height: 1.5rem;
}
.badge.badge-sm.badge-circle {
  width: 1.5rem;
  height: 1.5rem;
}
.badge.badge-lg {
  min-width: 2rem;
  font-size: 1rem;
}
.badge.badge-lg.badge-square {
  height: 2rem;
}
.badge.badge-lg.badge-circle {
  width: 2rem;
  height: 2rem;
}
.badge-white {
  color: var(--ez-white-inverse);
  background-color: var(--ez-white);
}
.badge-white.badge-outline {
  border: 1px solid var(--ez-white);
  color: var(--ez-white);
  background-color: transparent;
}
.badge-light-white {
  color: var(--ez-white);
  background-color: var(--ez-white-light);
}
.badge-light {
  color: var(--ez-light-inverse);
  background-color: var(--ez-light);
}
.badge-light.badge-outline {
  border: 1px solid var(--ez-light);
  color: var(--ez-light);
  background-color: transparent;
}
.badge-light-light {
  color: var(--ez-light);
  background-color: var(--ez-light-light);
}
.badge-primary {
  color: var(--ez-primary-inverse);
  background-color: var(--ez-primary);
}
.badge-primary.badge-outline {
  border: 1px solid var(--ez-primary);
  color: var(--ez-primary);
  background-color: transparent;
}
.badge-light-primary {
  color: var(--ez-primary);
  background-color: var(--ez-primary-light);
}
.badge-secondary {
  color: var(--ez-secondary-inverse);
  background-color: var(--ez-secondary);
}
.badge-secondary.badge-outline {
  border: 1px solid var(--ez-secondary);
  color: var(--ez-secondary);
  background-color: transparent;
}
.badge-light-secondary {
  color: var(--ez-secondary);
  background-color: var(--ez-secondary-light);
}
.badge-success {
  color: var(--ez-success-inverse);
  background-color: var(--ez-success);
}
.badge-success.badge-outline {
  border: 1px solid var(--ez-success);
  color: var(--ez-success);
  background-color: transparent;
}
.badge-light-success {
  color: var(--ez-success);
  background-color: var(--ez-success-light);
}
.badge-info {
  color: var(--ez-info-inverse);
  background-color: var(--ez-info);
}
.badge-info.badge-outline {
  border: 1px solid var(--ez-info);
  color: var(--ez-info);
  background-color: transparent;
}
.badge-light-info {
  color: var(--ez-info);
  background-color: var(--ez-info-light);
}
.badge-warning {
  color: var(--ez-warning-inverse);
  background-color: var(--ez-warning);
}
.badge-warning.badge-outline {
  border: 1px solid var(--ez-warning);
  color: var(--ez-warning);
  background-color: transparent;
}
.badge-light-warning {
  color: var(--ez-warning);
  background-color: var(--ez-warning-light);
}
.badge-danger {
  color: var(--ez-danger-inverse);
  background-color: var(--ez-danger);
}
.badge-danger.badge-outline {
  border: 1px solid var(--ez-danger);
  color: var(--ez-danger);
  background-color: transparent;
}
.badge-light-danger {
  color: var(--ez-danger);
  background-color: var(--ez-danger-light);
}
.badge-dark {
  color: var(--ez-dark-inverse);
  background-color: var(--ez-dark);
}
.badge-dark.badge-outline {
  border: 1px solid var(--ez-dark);
  color: var(--ez-dark);
  background-color: transparent;
}
.badge-light-dark {
  color: var(--ez-dark);
  background-color: var(--ez-dark-light);
}
/**Font weight**/
.fw-light {
  font-weight: 300 !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-bold {
  font-weight: 600 !important;
}
.fw-semibold {
  font-weight: 500 !important;
}
.fw-bolder {
  font-weight: 700 !important;
}
/**Font size**/
.fs-3px {
  font-size: 3px !important;
}
.fs-4px {
  font-size: 4px !important;
}
.fs-5px {
  font-size: 5px !important;
}
.fs-6px {
  font-size: 6px !important;
}
.fs-7px {
  font-size: 7px !important;
}
.fs-8px {
  font-size: 8px !important;
}
.fs-9px {
  font-size: 9px !important;
}
.fs-10px {
  font-size: 10px !important;
}
.fs-11px {
  font-size: 11px !important;
}
.fs-12px {
  font-size: 12px !important;
}
.fs-13px {
  font-size: 13px !important;
}
.fs-14px {
  font-size: 14px !important;
}
.fs-15px {
  font-size: 15px !important;
}
.fs-16px {
  font-size: 16px !important;
}
.fs-17px {
  font-size: 17px !important;
}
.fs-18px {
  font-size: 18px !important;
}
.fs-19px {
  font-size: 19px !important;
}
.fs-20px {
  font-size: 20px !important;
}
.fs-21px {
  font-size: 21px !important;
}
.fs-22px {
  font-size: 22px !important;
}
.fs-23px {
  font-size: 23px !important;
}
.fs-24px {
  font-size: 24px !important;
}
.fs-25px {
  font-size: 25px !important;
}
/**Gap**/
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.gap-3 {
  gap: 0.75rem !important;
}
.gap-4 {
  gap: 1rem !important;
}
.gap-5 {
  gap: 1.25rem !important;
}
.gap-6 {
  gap: 1.5rem !important;
}
.gap-7 {
  gap: 1.75rem !important;
}
.gap-8 {
  gap: 2rem !important;
}
.gap-9 {
  gap: 2.25rem !important;
}
.gap-10 {
  gap: 2.5rem !important;
}
.gap-11 {
  gap: 2.75rem !important;
}
.gap-12 {
  gap: 3rem !important;
}
.gap-13 {
  gap: 3.25rem !important;
}
.gap-14 {
  gap: 3.5rem !important;
}
.gap-15 {
  gap: 3.75rem !important;
}
.gap-16 {
  gap: 4rem !important;
}
.gap-17 {
  gap: 4.25rem !important;
}
.gap-18 {
  gap: 4.5rem !important;
}
.gap-19 {
  gap: 4.75rem !important;
}
.gap-20 {
  gap: 5rem !important;
}
.text-underline-hover:hover {
  text-decoration: underline;
}
.cursor-pointer-hover:hover {
  cursor: pointer;
}
.text-nowrap {
  white-space: nowrap;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
.max-width-200px {
  max-width: 300px;
  overflow: hidden;
}
.max-width-300px {
  max-width: 300px;
  overflow: hidden;
}
.full-size-modal {
  width: 100% !important;
  top: 0;
  max-width: 100% !important;
  padding: 0!important;
  margin: 0!important;
}
.full-size-modal .ant-modal-content {
  min-height: 100vh !important;
}
/**Ripple Dot**/
.ripple-dot {
  position: absolute;
  width: 0;
  height: 0;
  top: 3px;
  right: 3px;
}
.ripple-dot .dot {
  position: absolute;
  width: 14px;
  height: 14px;
}
.ripple-dot .dot .dot-inner {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: #43ced7;
  border-radius: 50%;
}
.ripple-dot.animated .dot .dot-outer {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) scale(4);
  background-color: #43ced7;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) inset;
  animation: ripple 1s infinite;
  animation-timing-function: ease;
}
.ripple-dot.info .dot .dot-inner,
.ripple-dot.info .dot .dot-outer {
  background-color: #009ef7;
}
.ripple-dot.danger .dot .dot-inner,
.ripple-dot.danger .dot .dot-outer {
  background-color: #f1416c;
}
.ripple-dot.success .dot .dot-inner,
.ripple-dot.success .dot .dot-outer {
  background-color: #f1416c;
}
.ripple-dot.warning .dot .dot-inner,
.ripple-dot.warning .dot .dot-outer {
  background-color: #ffc700;
}
.ripple-dot.primary .dot .dot-inner,
.ripple-dot.primary .dot .dot-outer {
  background-color: #43ced7;
}
.ripple-dot.secondary .dot .dot-inner,
.ripple-dot.secondary .dot .dot-outer {
  background-color: #7239ea;
}
@keyframes ripple {
  0% {
    opacity: 0.85;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(3);
  }
}
/**End Ripple Dot**/

