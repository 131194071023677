.fi {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.ant-btn.btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}