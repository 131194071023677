.ez-form-viewer-wrapper .ez-form-viewer-field {
    margin-bottom: 24px;
}
.ez-form-viewer-wrapper .ez-form-viewer-field label {
    display: block;
    font-size: 15px;
    font-weight: 300;
    color: #7e8299;
}
.ez-form-viewer-wrapper .ez-form-viewer-field .no-value {
    font-size: 13px;
    font-weight: 200;
    color: #b5b5c3;
    font-style: italic;
}
.ez-form-viewer-wrapper .ez-form-viewer-field .field-value {
    font-size: 15px;
    font-weight: 500;
    color: #3f4254;
}

/**Table**/
.ez-form-viewer-table-field {
    margin-bottom: 24px;
}
.ez-form-viewer-table-field .ez-form-viewer-table-field-wrapper {
    overflow-x: auto;
}
.ez-form-viewer-table-field table.table-field {
    border-collapse: collapse;
    width: max-content;
    border: 1px solid #eff2f5;
    min-width: 100%;
}
.ez-form-viewer-table-field table.table-field tr {
    border-top: 1px solid #eff2f5;
    border-bottom: 1px solid #eff2f5;
}
.ez-form-viewer-table-field table.table-field td,
.ez-form-viewer-table-field table.table-field th {
    padding: 7px;
}
.ez-form-viewer-table-field table.table-field th{
    text-align: left;
    background: #F7FAFC;
    color: #7e8299;
    font-weight: 500;
}
.ez-form-viewer-table-field .ez-form-viewer-field {
    margin-bottom: 0;
}
.ez-form-viewer-table-field .ez-form-viewer-field .field-value {
    font-size: 14px;
    font-weight: 400;
    color: #3f4254;
}
/**End Table**/

/**Multi field**/
.ez-form-viewer-field-group .ez-form-viewer-field-group-header {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 17px;
    background: #F7FAFC;
    color: #7e8299;
    border: 1px solid #eff2f5;
    border-bottom: none;
}
.ez-form-viewer-field-group .ez-form-viewer-field-group-header label {
    font-weight: 500;
    margin-right: 7px;
}
.ez-form-viewer-field-group .ez-form-viewer-field-group-header span {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
    line-height: 22px;
    font-size: 14px;
    color: #fff;
    background: #009ef7;
}
.ez-form-viewer-field-group .ez-form-viewer-field-group-container {
    border: 1px solid #eff2f5;
    padding: 17px;
    margin-bottom: 24px;
}
.ez-form-viewer-field-group .ez-form-viewer-field-group-container .ez-form-viewer-field:last-child {
    margin-bottom: 0;
}
/**End Multi field**/

/**Video**/
.ez-form-viewer-field.video .video-thumbnail {
    width: 200px;
    height: 112px;
    background-color: #333;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.ez-form-viewer-field.video .video-thumbnail img {
    width: 45px;
}
/**End: Video**/
/**Text**/
.ez-form-viewer-field.text .html-content {
    opacity: 0.9;
}
/**End: Text**/
/**Checkboxes**/
.ez-form-viewer-field.checkboxes .checkbox-item {
    display: block;
    margin-bottom: 7px;
}
/**End: Checkboxes**/
/**Checkboxes**/
.ez-form-viewer-field.upload .file-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 7px 0;
}
.ez-form-viewer-field.upload .file-item {
    display: inline-flex;
    align-items: center;
    gap: 7px;
    padding: 0 7px;
    background: #f1f1f1;
    border-radius: 10px;
    font-weight: 500;
    cursor: pointer;
}
.ez-form-viewer-field.upload .file-item:hover span {
    text-decoration: underline;
}
/**End: Checkboxes**/
