.primary-layout .page-header-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.primary-layout .page-header-actions .page-header-action {
  margin-left: 10px;
  width: 45px;
  height: 45px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--ez-border-radius);
  cursor: pointer;
}
.primary-layout .page-header-actions .page-header-action:hover {
  color: #3699FF;
  background-color: #F3F6F9;
}
.primary-layout .page-header-actions .page-header-action i {
  margin-top: 1px;
}
.primary-layout .page-header-actions .page-header-user {
  width: auto;
  margin-left: 10px;
  padding: 0 7px;
  height: 45px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--ez-border-radius);
  cursor: pointer;
  background-color: #F3F6F9;
}
.primary-layout .page-header-actions .page-header-user:hover {
  color: #3699FF;
  background-color: #F3F6F9;
}
.primary-layout .page-header-actions .page-header-user .header-user-name {
  color: #7E8299;
  font-size: 15px;
  font-weight: bold;
  padding-left: 7px;
}
.primary-layout .page-header-actions .page-header-user .header-user-icon {
  margin-left: 7px;
  background: #60AEFF;
  width: 35px;
  height: 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  border-radius: var(--ez-border-radius);
}
.primary-layout .header-user-menu .header-user-menu-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
