.group-page-content .group-user-count {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.group-page-content .group-user-count span {
  font-size: 15px;
  font-weight: 500;
}
.group-page-content .group-user-count .fi {
  font-size: 15px;
  color: #777;
}
.group-page-content .group-authorization-allow .fi {
  font-size: 17px;
}
.group-detail-title {
  padding: 20px 0;
}
.permission-assignment__wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.permission-assignment__wrap .ant-slider {
  margin-top: 0!important;
}
.permission-assignment__wrap .ant-slider-mark {
  display: none;
}
.permission-assignment__wrap .ant-slider-with-marks {
  margin-bottom: 0!important;
}
.permission-assignment__row {
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: 15px 20px;
  border: 1px solid #f1f1f1;
}
.permission-assignment__row__title {
  width: 350px;
}
.permission-assignment__row__value {
  width: calc(100% - 350px);
}
.permission-assignment__header {
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: 15px 20px;
  border: 1px solid #f1f1f1;
  background-color: #fafafa;
}
.permission-assignment__header__title {
  width: 350px;
  font-weight: 600;
}
.permission-assignment__header__description {
  width: calc(100% - 350px);
  display: flex;
  font-weight: 600;
}
.permission-assignment__header__description span {
  flex: 1;
}
.permission-assignment__header__description span:nth-child(2) {
  text-align: center;
}
.permission-assignment__header__description span:last-child {
  text-align: right;
}
