.drive-picker__modal .ant-modal-body {
  max-height: 1200px;
  min-height: 500px;
  padding: 0;
  background-color: #F3F6F9;
  display: flex;
  flex-direction: column;
}
.drive-picker__breadcrumb {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 10px 1px;
  padding: 10px 24px;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
}
.drive-picker__breadcrumb span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  padding: 0 5px;
  min-height: 25px;
  border-radius: 2px;
  color: var(--ez-text-gray-700);
}
.drive-picker__breadcrumb span:hover {
  background-color: var(--ez-secondary);
}
.drive-picker__breadcrumb span.separator {
  background-color: transparent!important;
}
.drive-picker__breadcrumb span:last-child {
  background-color: transparent!important;
  cursor: default;
  color: var(--ez-text-dark);
}
.drive-picker__content-wrapper {
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.drive-picker__item {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border-radius: var(--ez-border-radius);
  border: 1px solid #ebedf3;
  margin-bottom: 10px;
  box-shadow: 0 10px 35px 0 rgba(56, 71, 109, 0.08);
  background: #fff;
  gap: 10px;
  cursor: pointer;
}
.drive-picker__item--space .drive-picker__item__icon {
  background-color: var(--ez-primary-light);
  color: var(--ez-primary);
}
.drive-picker__item--resource .drive-picker__item__icon {
  background-color: var(--ez-success-light);
  color: var(--ez-success);
}
.drive-picker__item--resource.selected {
  border-color: var(--ez-success);
}
.drive-picker__item__icon {
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  border-radius: 0.675rem;
  padding: 11px;
  background-color: #FFF8DD;
  color: #FFC700;
  font-size: 20px;
}
.drive-picker__item__content {
  overflow: hidden;
}
.drive-picker__empty {
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
}
.drive-picker__title {
  margin-bottom: 5px;
}
