
.container-board{
    /* background-color: rgb(185, 184, 184); */
    min-height: 100vh;
    max-width: 100vw;
    display: inline-flex;
    /* padding-left: 100px; */
    /* align-items: center; */
    margin-left: 20px;
}

.container-column {
    margin: 8px;
    /* padding: 10px; */
    width: 350px;
    display: flex;
    flex-direction: column;
    height: 30px;
    padding-right: 20px;
    border-radius: 20px;
    /* border-color: azure; */
}

.header-board {
    display:  flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 2px;
    border-bottom-right-radius: 2px;
    /* background-color: rgb(0, 130, 252); */
    background-color: rgb(176, 177, 179);
    transition: background-color 0.2s ease;
    &:hover {
        background-color: rgb(214, 215, 216);
        color: rgb(0, 0, 0);
    }
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.header .isDragging {
    background-color:  burlywood;
}

.title {
    padding: 8px;
    transition: background-color ease 0.2s;
    flex-grow: 1;
    user-select: none;
    position: relative;
    &:focus {
        outline: 2px solid #998dd9;
        outline-offset: 2px;
    }
}
.Wrapper {
    display: flex;
    flex-direction: column;
    user-select: none;
    width: 330px;
    background-color: rgb(233, 236, 240);
    transition: background-color 0.2s ease, opacity 0.1s ease;
  }
  
  .Wrapper {
    padding: 8px;
    border: 8px;
    padding-bottom: 0;
    /* background-color: ${({ isDraggingOver, isDraggingFrom }) => getBackgroundColor(isDraggingOver, isDraggingFrom)}; */
  }
  
  /* .Wrapper {
    opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
  } */
.dropzone{
    min-height: 250px;
    padding-bottom: 8px;
}

.scroll-container {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 500px;
  }

.item {
    background-color: rgb(252, 254, 255);
    margin-bottom: 10px;
    border-radius: 5px;
    height: auto;
    display: flex;
}  

.avatar{
    /* width: 30px;
    height: 30px; */
    /* margin: 10px; */
    padding: 10px;
}
.content {
    font-size: 16px;
    padding: 10px;
}
.version {
    padding: 10px;
    color: black;
}